import { default as change_45password159PxjrMDCMeta } from "/usr/src/nuxt-app/pages/account-customer/change-password.vue?macro=true";
import { default as chatPlT2DzWB0aMeta } from "/usr/src/nuxt-app/pages/account-customer/chat.vue?macro=true";
import { default as favoritesJHVqC8sxd4Meta } from "/usr/src/nuxt-app/pages/account-customer/favorites.vue?macro=true";
import { default as _91uuid_93JlGF93BEGWMeta } from "/usr/src/nuxt-app/pages/account-customer/layout/[uuid].vue?macro=true";
import { default as add_45new_45layoutEIzBKvFa1YMeta } from "/usr/src/nuxt-app/pages/account-customer/layout/add-new-layout.vue?macro=true";
import { default as add_45layoutNaiEpM98M8Meta } from "/usr/src/nuxt-app/pages/account-customer/layouts/add-layout.vue?macro=true";
import { default as indexrMKfwa7sl4Meta } from "/usr/src/nuxt-app/pages/account-customer/layouts/index.vue?macro=true";
import { default as purchasesbFXoCQGb4cMeta } from "/usr/src/nuxt-app/pages/account-customer/purchases.vue?macro=true";
import { default as requests9YDiJ8ThaTMeta } from "/usr/src/nuxt-app/pages/account-customer/requests.vue?macro=true";
import { default as settingsM1Cy8us7nbMeta } from "/usr/src/nuxt-app/pages/account-customer/settings.vue?macro=true";
import { default as change_45password9FYHFlwfq4Meta } from "/usr/src/nuxt-app/pages/account-seller/change-password.vue?macro=true";
import { default as chat6jQFZ6fTLiMeta } from "/usr/src/nuxt-app/pages/account-seller/chat.vue?macro=true";
import { default as organizationH1zW4AAo00Meta } from "/usr/src/nuxt-app/pages/account-seller/organization.vue?macro=true";
import { default as requestsbfjJFciJK6Meta } from "/usr/src/nuxt-app/pages/account-seller/requests.vue?macro=true";
import { default as safetyNP4YJjbCkAMeta } from "/usr/src/nuxt-app/pages/account-seller/safety.vue?macro=true";
import { default as statlXIUo6fX65Meta } from "/usr/src/nuxt-app/pages/account-seller/stat.vue?macro=true";
import { default as login6Y49Qe3BVbMeta } from "/usr/src/nuxt-app/pages/auth/login.vue?macro=true";
import { default as payment3nm6arDBdcMeta } from "/usr/src/nuxt-app/pages/auth/payment.vue?macro=true";
import { default as registration_45customerm0PMn7kbHQMeta } from "/usr/src/nuxt-app/pages/auth/registration-customer.vue?macro=true";
import { default as registration_45sellerh3H4i6pw2gMeta } from "/usr/src/nuxt-app/pages/auth/registration-seller.vue?macro=true";
import { default as reset_45passwordxjbKLbTaT9Meta } from "/usr/src/nuxt-app/pages/auth/reset-password.vue?macro=true";
import { default as send_45reset_45password_45linkY1aGuoZhvtMeta } from "/usr/src/nuxt-app/pages/auth/send-reset-password-link.vue?macro=true";
import { default as verify_45userCICNdktaqwMeta } from "/usr/src/nuxt-app/pages/auth/verify-user.vue?macro=true";
import { default as indexAoBMx6PnC1Meta } from "/usr/src/nuxt-app/pages/index.vue?macro=true";
import { default as investorsDAxxJuzdMiMeta } from "/usr/src/nuxt-app/pages/investors.vue?macro=true";
import { default as _91slug_93wkBDbFQkHIMeta } from "/usr/src/nuxt-app/pages/news/[slug].vue?macro=true";
import { default as indexAqnIhg14ssMeta } from "/usr/src/nuxt-app/pages/news/index.vue?macro=true";
import { default as sellerlX3io9K2mKMeta } from "/usr/src/nuxt-app/pages/seller.vue?macro=true";
export default [
  {
    name: "account-customer-change-password___ru",
    path: "/account-customer/change-password",
    meta: change_45password159PxjrMDCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/change-password.vue")
  },
  {
    name: "account-customer-change-password___en",
    path: "/en/account-customer/change-password",
    meta: change_45password159PxjrMDCMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/change-password.vue")
  },
  {
    name: "account-customer-chat___ru",
    path: "/account-customer/chat",
    meta: chatPlT2DzWB0aMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/chat.vue")
  },
  {
    name: "account-customer-chat___en",
    path: "/en/account-customer/chat",
    meta: chatPlT2DzWB0aMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/chat.vue")
  },
  {
    name: "account-customer-favorites___ru",
    path: "/account-customer/favorites",
    meta: favoritesJHVqC8sxd4Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/favorites.vue")
  },
  {
    name: "account-customer-favorites___en",
    path: "/en/account-customer/favorites",
    meta: favoritesJHVqC8sxd4Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/favorites.vue")
  },
  {
    name: "account-customer-layout-uuid___ru",
    path: "/account-customer/layout/:uuid()",
    meta: _91uuid_93JlGF93BEGWMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layout/[uuid].vue")
  },
  {
    name: "account-customer-layout-uuid___en",
    path: "/en/account-customer/layout/:uuid()",
    meta: _91uuid_93JlGF93BEGWMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layout/[uuid].vue")
  },
  {
    name: "account-customer-layout-add-new-layout___ru",
    path: "/account-customer/layout/add-new-layout",
    meta: add_45new_45layoutEIzBKvFa1YMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layout/add-new-layout.vue")
  },
  {
    name: "account-customer-layout-add-new-layout___en",
    path: "/en/account-customer/layout/add-new-layout",
    meta: add_45new_45layoutEIzBKvFa1YMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layout/add-new-layout.vue")
  },
  {
    name: "account-customer-layouts-add-layout___ru",
    path: "/account-customer/layouts/add-layout",
    meta: add_45layoutNaiEpM98M8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layouts/add-layout.vue")
  },
  {
    name: "account-customer-layouts-add-layout___en",
    path: "/en/account-customer/layouts/add-layout",
    meta: add_45layoutNaiEpM98M8Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layouts/add-layout.vue")
  },
  {
    name: "account-customer-layouts___ru",
    path: "/account-customer/layouts",
    meta: indexrMKfwa7sl4Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layouts/index.vue")
  },
  {
    name: "account-customer-layouts___en",
    path: "/en/account-customer/layouts",
    meta: indexrMKfwa7sl4Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/layouts/index.vue")
  },
  {
    name: "account-customer-purchases___ru",
    path: "/account-customer/purchases",
    meta: purchasesbFXoCQGb4cMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/purchases.vue")
  },
  {
    name: "account-customer-purchases___en",
    path: "/en/account-customer/purchases",
    meta: purchasesbFXoCQGb4cMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/purchases.vue")
  },
  {
    name: "account-customer-requests___ru",
    path: "/account-customer/requests",
    meta: requests9YDiJ8ThaTMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/requests.vue")
  },
  {
    name: "account-customer-requests___en",
    path: "/en/account-customer/requests",
    meta: requests9YDiJ8ThaTMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/requests.vue")
  },
  {
    name: "account-customer-settings___ru",
    path: "/account-customer/settings",
    meta: settingsM1Cy8us7nbMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/settings.vue")
  },
  {
    name: "account-customer-settings___en",
    path: "/en/account-customer/settings",
    meta: settingsM1Cy8us7nbMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-customer/settings.vue")
  },
  {
    name: "account-seller-change-password___ru",
    path: "/account-seller/change-password",
    meta: change_45password9FYHFlwfq4Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-seller/change-password.vue")
  },
  {
    name: "account-seller-change-password___en",
    path: "/en/account-seller/change-password",
    meta: change_45password9FYHFlwfq4Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-seller/change-password.vue")
  },
  {
    name: "account-seller-chat___ru",
    path: "/account-seller/chat",
    meta: chat6jQFZ6fTLiMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-seller/chat.vue")
  },
  {
    name: "account-seller-chat___en",
    path: "/en/account-seller/chat",
    meta: chat6jQFZ6fTLiMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-seller/chat.vue")
  },
  {
    name: "account-seller-organization___ru",
    path: "/account-seller/organization",
    meta: organizationH1zW4AAo00Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-seller/organization.vue")
  },
  {
    name: "account-seller-organization___en",
    path: "/en/account-seller/organization",
    meta: organizationH1zW4AAo00Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-seller/organization.vue")
  },
  {
    name: "account-seller-requests___ru",
    path: "/account-seller/requests",
    meta: requestsbfjJFciJK6Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-seller/requests.vue")
  },
  {
    name: "account-seller-requests___en",
    path: "/en/account-seller/requests",
    meta: requestsbfjJFciJK6Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-seller/requests.vue")
  },
  {
    name: "account-seller-safety___ru",
    path: "/account-seller/safety",
    meta: safetyNP4YJjbCkAMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-seller/safety.vue")
  },
  {
    name: "account-seller-safety___en",
    path: "/en/account-seller/safety",
    meta: safetyNP4YJjbCkAMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-seller/safety.vue")
  },
  {
    name: "account-seller-stat___ru",
    path: "/account-seller/stat",
    meta: statlXIUo6fX65Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-seller/stat.vue")
  },
  {
    name: "account-seller-stat___en",
    path: "/en/account-seller/stat",
    meta: statlXIUo6fX65Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/account-seller/stat.vue")
  },
  {
    name: "auth-login___ru",
    path: "/auth/login",
    meta: login6Y49Qe3BVbMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/login.vue")
  },
  {
    name: "auth-login___en",
    path: "/en/auth/login",
    meta: login6Y49Qe3BVbMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/login.vue")
  },
  {
    name: "auth-payment___ru",
    path: "/auth/payment",
    meta: payment3nm6arDBdcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/payment.vue")
  },
  {
    name: "auth-payment___en",
    path: "/en/auth/payment",
    meta: payment3nm6arDBdcMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/payment.vue")
  },
  {
    name: "auth-registration-customer___ru",
    path: "/auth/registration-customer",
    meta: registration_45customerm0PMn7kbHQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/registration-customer.vue")
  },
  {
    name: "auth-registration-customer___en",
    path: "/en/auth/registration-customer",
    meta: registration_45customerm0PMn7kbHQMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/registration-customer.vue")
  },
  {
    name: "auth-registration-seller___ru",
    path: "/auth/registration-seller",
    meta: registration_45sellerh3H4i6pw2gMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/registration-seller.vue")
  },
  {
    name: "auth-registration-seller___en",
    path: "/en/auth/registration-seller",
    meta: registration_45sellerh3H4i6pw2gMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/registration-seller.vue")
  },
  {
    name: "auth-reset-password___ru",
    path: "/auth/reset-password",
    meta: reset_45passwordxjbKLbTaT9Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/reset-password.vue")
  },
  {
    name: "auth-reset-password___en",
    path: "/en/auth/reset-password",
    meta: reset_45passwordxjbKLbTaT9Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/reset-password.vue")
  },
  {
    name: "auth-send-reset-password-link___ru",
    path: "/auth/send-reset-password-link",
    meta: send_45reset_45password_45linkY1aGuoZhvtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/send-reset-password-link.vue")
  },
  {
    name: "auth-send-reset-password-link___en",
    path: "/en/auth/send-reset-password-link",
    meta: send_45reset_45password_45linkY1aGuoZhvtMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/send-reset-password-link.vue")
  },
  {
    name: "auth-verify-user___ru",
    path: "/auth/verify-user",
    meta: verify_45userCICNdktaqwMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/verify-user.vue")
  },
  {
    name: "auth-verify-user___en",
    path: "/en/auth/verify-user",
    meta: verify_45userCICNdktaqwMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/auth/verify-user.vue")
  },
  {
    name: "index___ru",
    path: "/",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexAoBMx6PnC1Meta || {},
    component: () => import("/usr/src/nuxt-app/pages/index.vue")
  },
  {
    name: "investors___ru",
    path: "/investors",
    meta: investorsDAxxJuzdMiMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/investors.vue")
  },
  {
    name: "investors___en",
    path: "/en/investors",
    meta: investorsDAxxJuzdMiMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/investors.vue")
  },
  {
    name: "news-slug___ru",
    path: "/news/:slug()",
    meta: _91slug_93wkBDbFQkHIMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/news/[slug].vue")
  },
  {
    name: "news-slug___en",
    path: "/en/news/:slug()",
    meta: _91slug_93wkBDbFQkHIMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/news/[slug].vue")
  },
  {
    name: "news___ru",
    path: "/news",
    meta: indexAqnIhg14ssMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/news/index.vue")
  },
  {
    name: "news___en",
    path: "/en/news",
    meta: indexAqnIhg14ssMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/news/index.vue")
  },
  {
    name: "seller___ru",
    path: "/seller",
    meta: sellerlX3io9K2mKMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/seller.vue")
  },
  {
    name: "seller___en",
    path: "/en/seller",
    meta: sellerlX3io9K2mKMeta || {},
    component: () => import("/usr/src/nuxt-app/pages/seller.vue")
  }
]
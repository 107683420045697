export const useConfigStore = defineStore('config', () => {
  const env = ref<Config>({
    baseCentrifugoURL: '',
    socketCentrifugoURL: '',
    baseWidgetUrl: '',
    baseMiniAppURL: '',
    apiKey: '',
    apiKeyMaps: '',
  
  })
  async function getEnv() {
    const resp: Config = await $fetch('/api/config')
    env.value = resp
  }
  return {
    env,
    getEnv,
  }
})

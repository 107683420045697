export default defineNuxtPlugin((nuxtApp) => {
  const userAuth = useCookie<string | null>('access')
  const refresh = useCookie<string | null>('refresh')
  const config = useRuntimeConfig()

  const api = $fetch.create({
    baseURL: config.public.baseURL,
    onRequest({ options }) {
      options.headers = options.headers as Record<string, string> || {}

      if (userAuth.value && !options.headers.Authorization) {
        options.headers.Authorization = `Bearer ${userAuth.value}`
      }
    },
    async onResponseError({ response }) {
      if (response.status === 401) {
        userAuth.value = null
        refresh.value = null
        // await navigateTo(`/auth/login`)
        await nuxtApp.runWithContext(() => navigateTo(`/auth/login`))
      }
    },
  })

  return {
    provide: {
      api,
    },
  }
})

import unhead_KgADcZ0jPj from "/usr/src/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sounds_7noHXeIa7n from "/usr/src/nuxt-app/node_modules/@vueuse/sound/dist/runtime/plugins/sounds.ts";
import primevue_plugin_egKpok8Auk from "/usr/src/nuxt-app/.nuxt/primevue-plugin.mjs";
import plugin_client_8fz26Kfk5j from "/usr/src/nuxt-app/node_modules/nuxt-primevue/dist/runtime/plugin.client.mjs";
import composition_sLxaNGmlSL from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/composition.mjs";
import i18n_yfWm7jX06p from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import api_GFfDXud5Cr from "/usr/src/nuxt-app/plugins/api.ts";
import sentry_client_shVUlIjFLk from "/usr/src/nuxt-app/plugins/sentry.client.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  sounds_7noHXeIa7n,
  primevue_plugin_egKpok8Auk,
  plugin_client_8fz26Kfk5j,
  composition_sLxaNGmlSL,
  i18n_yfWm7jX06p,
  api_GFfDXud5Cr,
  sentry_client_shVUlIjFLk
]